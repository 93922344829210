import React, { useContext } from 'react'
import { FiChevronRight } from "react-icons/fi";
import { GlobalDataContext } from '../../context/context';


const dbInfo = [
    {
        title: 'CRANE COMPANY SERVING IN NJ',
        Presentation: [
            {
                titulo: 'Gentlemen Present:'
            },
            {
                titulo: 'Attention:'
            },
            {
                titulo: 'From our consideration:'
            },
        ],
        Description: 'We are pleased to address you, cordially greet you, and at the same time introduce you to our company SISA FABRICATORS LLC, a company that offers Crane Services and is supported by a boom truck with a capacity of:',
        listServices: [
            {
                description: 'Max 30-1on (27.2 m ton) Capacity'
            },
            {
                description: '5-Section 1247 (37.8 m)Proportional Boom'
            },
            {
                description: '1-Piece 31 (95m) Jib'
            },
            {
                description: '164" (50 m) Maximum Tip Height'
            },
            {
                description: '20`5" (62 m) X-Style symmetrical Outrigger Spread outrigger radio remote controller'
            },
            {
                description: 'Pilot operated Controls with Optional Free Swing with'
            },
            {
                description: '2-Speed Planetary Hoist with Grooved Drum. Negative Draft'
            },
            {
                description: 'Dual Load Chart for use with and without Counterweight'
            },
            {
                description: 'Load Moment Indicator with Digital Display. CAN Bus, Overload Shutdown and Internal Boom Length Cable'
            },
            {
                description: 'ATB Radio'
            },
            {
                description: 'Rugged, Weatherproof Electrical System with Circuit Status LEDS'
            },
            {
                description: 'Removable Boom Rest'
            },
            {
                description: 'System Pressure Gauge'
            },
            {
                description: 'Continuous Rotation'
            },
            {
                description: 'Clamp-On Mounting'
            },
            {
                description: 'Comprehensive Support'
            },
        ],
        Description2: 'our work is executed on time, generating greater profitability in the investments of our customers, has tools, machinery, instruments, and equipment, allowing us to satisfy the requirements of our customers with effectiveness, warranty, quality, speed, cost, and personalized attention, also documents and certifies all solutions and work performed, since we are also focused on raising the quality of working life of our users.',
        cost: [
            {
                desc: 'Installation per day $1,400.00'
            },
            {
                desc: 'installation for a full week $1200.00 discount of 200 x day'
            },
        ],


    },
]

function BlockHomeInfo() {
    const { rpdata } = useContext(GlobalDataContext)
    return (
        <div className='w-full h-auto py-10 flex justify-center my-10'>
            <div className='w-[90%] p-6 h-auto bg-slate-100 rounded-2xl flex'>
                <div className='md:w-[55%] w-full'>
                    {
                        dbInfo.map((item, index) => {
                            return (
                                <div key={index}>
                                    <h2>{item.title}</h2>
                                    <div>
                                        <ul>
                                            {
                                                item.Presentation.map((pres, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <span>{pres.titulo}</span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        <p>{item.Description}</p>
                                    </div>
                                    <ul className='px-5'>
                                        {
                                            item.listServices.map((lser, index) => {
                                                return (
                                                    <li className='flex items-center gap-4 pb-2' key={index}>
                                                        <FiChevronRight />
                                                        <span>{lser.description}</span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <p className='pb-5'>{item.Description2}</p>
                                    <h5 className='text-[20px] font-bold'>COST OF SERVICE:</h5>
                                    <ul className='pb-4'>
                                        {
                                            item.cost.map((cost, index) => {
                                                return (
                                                    <li key={index}>{cost.desc}</li>
                                                );
                                            })
                                        }
                                    </ul>
                                    <div className='pb-4'>
                                        <h5 className='text-[20px] font-bold'>Location:</h5>
                                        <p>924 SPRING ST — ELIZABETH, NJ 07201</p>
                                    </div>
                                    <div>
                                        <h5 className='text-[20px] font-bold'>Contact Us:</h5>
                                        <p>PROJECT MANAGER: PAUL GUILLEN — 908-267-2100</p>
                                        <p>E-MAIL: pmanager@sisafabricator.com</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className='w-full md:w-[45%] ml-0 -mt-20 mb-0 md:ml-2 md:-my-20'>
                    <img
                        src={rpdata?.gallery?.[0]}
                        alt='no found'
                        loading='lazy'
                        className='w-full h-[300px] md:h-full object-cover rounded-2xl'
                    />
                </div>
            </div>
        </div>
    )
}

export default BlockHomeInfo